{
  "name": "tfgh",
  "version": "0.0.0",
  "type": "module",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "dev-api": "ng serve --configuration development",
    "prod-api": "ng serve --configuration production",
    "uat-api": "ng serve --configuration uat",
    "build": "ng build --configuration production",
    "build-dev": "ng build --configuration development",
    "build-prod": "ng build --configuration production",
    "build-uat": "ng build --configuration uat",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "serve:ssr:TFGH": "node dist/tfgh/server/server.mjs"
  },
  "private": true,
  "dependencies": {
    "@abacritt/angularx-social-login": "^2.3.0",
    "@angular/animations": "^18.1.0",
    "@angular/common": "^18.1.0",
    "@angular/compiler": "^18.1.0",
    "@angular/core": "^18.1.0",
    "@angular/forms": "^18.1.0",
    "@angular/platform-browser": "^18.1.0",
    "@angular/platform-browser-dynamic": "^18.1.0",
    "@angular/platform-server": "^18.1.0",
    "@angular/router": "^18.1.0",
    "@angular/ssr": "^18.1.3",
    "@ngxs/devtools-plugin": "^18.1.1",
    "@ngxs/logger-plugin": "^18.1.1",
    "@ngxs/router-plugin": "^18.1.1",
    "@ngxs/store": "^18.1.1",
    "angular-svg-icon": "^18.0.1",
    "chart.js": "^4.4.6",
    "express": "^4.18.2",
    "jwt-decode": "^4.0.0",
    "lodash-es": "^4.17.21",
    "ngx-permissions": "^17.1.0",
    "primeng": "^17.18.7",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "uuid": "^10.0.0",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.1.3",
    "@angular-eslint/builder": "^18.2.0",
    "@angular-eslint/eslint-plugin": "^18.2.0",
    "@angular-eslint/template-parser": "^18.2.0",
    "@angular/cli": "^18.1.3",
    "@angular/compiler-cli": "^18.1.0",
    "@faker-js/faker": "^8.4.1",
    "@types/express": "^4.17.17",
    "@types/jasmine": "~5.1.0",
    "@types/lodash-es": "^4.17.12",
    "@types/node": "^18.18.0",
    "@types/uuid": "^10.0.0",
    "autoprefixer": "^10.4.20",
    "eslint": "^9.8.0",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "postcss": "^8.4.41",
    "prettier": "^3.3.3",
    "tailwindcss": "^3.4.7",
    "typescript": "~5.5.2"
  }
}
